import React, { useState, useEffect } from 'react'
import styles from './index.module.scss'
import classNames from 'classnames'
import Search from '@/assets/svg/search.svg'
import dynamic from "next/dynamic";

const ListType14 = dynamic(()=>import('@/components/List/type14'))

export default function HeaderSearch({
  searchPlaceholder,
}: {
  searchPlaceholder: string
}) {
  const [list, setList] = useState([])
  const [isShow, setIsShow] = useState(false)
  const [value, setValue] = useState('')

  const handelChange = async (e: any) => {
    if (e.target.value.trim() === '') {
      setValue('')
      setIsShow(false)
      setList([])
      return
    }
    try {
      setValue(e.target.value.trim())
      setIsShow(true)
        const axios = (await import('@/api/index')).default
      const { data } = await axios.post('/api/search-store', {
        keyword: e.target.value.trim(),
      })
      if (data.code === 0) {
        setList(data.data.stores)
      }
    } catch (err) {}
  }

  function useDebounceFn(fn:any, delay:any) {
    const [lastArgs, setLastArgs] = useState([]);

    useEffect(() => {
      const handler = setTimeout(() => {
        if (lastArgs.length > 0) {
          fn(...lastArgs);
          setLastArgs([]);
        }
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }, [fn, delay, lastArgs]);

    return (...args:any) => {
      setLastArgs(args);
    };
  }
  return (
    <div className={styles.headerSearch}>
      <input
        style={{ verticalAlign: 'middle' }}
        type="text"
        placeholder={searchPlaceholder ?? 'Search On Timelycoupons'}
        className={styles.input}
        onFocus={() => {
          if (value && list.length) {
            setIsShow(true)
          }
        }}
        onChange={useDebounceFn(handelChange, 500)}
        onBlur={() => {
          setTimeout(() => {
            setIsShow(false)
          }, 300)
        }}
      />
      <button className={styles.btn_svg} aria-label="search button">
        <i>
          <Search></Search>
        </i>
      </button>
        {
            isShow && list.length ?
      <div
        className={classNames([
          styles.search_container,
          isShow && list.length && styles.isShow,
        ])}
      >
        <ListType14 list={list}></ListType14>
      </div> : null
        }
    </div>
  )
}
